import React, { useState } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Watermelon from './assets/watermelon.svg';
import PepeneSpart from './assets/pepine-spart.gif';

const Container = styled.div`
  margin-top: 5%;

  .pepene {
    height: 500px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const MetaWrapper = () => (
  <Helmet>
    <title>Pepini.XYZ</title>
    <style>
      {
        `
          body {
            background-color: #3c90be;
          }
        `
      }
    </style>
  </Helmet>
);

const App = () => {
  const [active, setActive] = useState(false);

  return (
    <>
      <MetaWrapper />
      <Container>
        <div class="content">
          {!active && <img src={Watermelon} onClick={() => setActive(true)} class="pepene" alt="Pepene" />}
          {active && <img src={PepeneSpart} onClick={() => setActive(false)} class="pepene" alt="Pepene" />}
        </div>
      </Container>
    </>
  );
};

export default App;
